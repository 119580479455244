import React from 'react';
import isDeepEqual from 'fast-deep-equal';
import { useDebouncedCallback } from 'use-debounce';
export default function useDebouncedCallbackOnChange(formik, callback) {
    var delay = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 1000;
    var debouncedValidateRef = React.useRef();
    var valuesRef = React.useRef(formik.initialValues);
    var debouncedValidate = useDebouncedCallback(function() {
        valuesRef.current = formik.values;
        callback();
    }, delay);
    React.useEffect(function() {
        debouncedValidateRef.current = debouncedValidate;
    }, [
        debouncedValidate
    ]);
    React.useEffect(function() {
        var _debouncedValidateRef_current;
        if (!isDeepEqual(valuesRef.current, formik.values)) {
            var _debouncedValidateRef_current1;
            (_debouncedValidateRef_current1 = debouncedValidateRef.current) === null || _debouncedValidateRef_current1 === void 0 ? void 0 : _debouncedValidateRef_current1.call(debouncedValidateRef);
        } else {
            var _debouncedValidateRef_current2;
            (_debouncedValidateRef_current2 = debouncedValidateRef.current) === null || _debouncedValidateRef_current2 === void 0 ? void 0 : _debouncedValidateRef_current2.cancel();
        }
        return (_debouncedValidateRef_current = debouncedValidateRef.current) === null || _debouncedValidateRef_current === void 0 ? void 0 : _debouncedValidateRef_current.cancel;
    }, [
        formik.values
    ]);
}
