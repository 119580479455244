import { SENTRY_DSN } from '@/utils/constants';
import * as Sentry from '@sentry/browser';
Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.NODE_ENV,
    denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i
    ],
    ignoreErrors: [
        'HTTPError',
        'postMessage'
    ]
});
export default Sentry;
